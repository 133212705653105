import React, {useState, useRef, useEffect} from 'react';
import {navigate} from '@reach/router';
import Typewriter from 'typewriter-effect';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/free-solid-svg-icons';

import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';
import {Translate} from '../../components/translations';
import Button from '../../components/button';
import '../../components/homePageServices/styles.scss';
import UserWrap from '../../components/userWrap';

import seo from '../../img/how-it-works/seo.png';
import smartphone from '../../img/how-it-works/smartphone-users.png';
import page404 from '../../img/how-it-works/404-page.png';
import analytics from '../../img/how-it-works/analytics.png';
import hexometerLogo from '../../img/hexometer-logo.svg';

import './styles.scss';

const fields = [
  {
    text: <Translate name="ABOUT_PARAGRAPH_ONE" />,
    image: smartphone,
    alt: 'eCommerce Website',
  },
  {
    text: <Translate name="ABOUT_PARAGRAPH_TWO" />,
    image: page404,
    alt: 'Broken Links',
  },
  {
    text: <Translate name="ABOUT_PARAGRAPH_THREE" />,
    image: seo,
    alt: 'Paid Ads and SEO',
  },
];

const texts = [
  'website uptime monitoring',
  'page speed checking',
  'error & broken page alerts',
  'user experience optimisation',
  'technical SEO audits',
  'vulnerability & security scanning',
];

const About = () => {
  const btnContent = useRef<any>(null);
  const videoContent = useRef<any>(null);
  const [showVideo, setShowVideo] = useState(false);

  const handleOutside = (e: {target: any}) => {
    if (videoContent && btnContent.current && btnContent && videoContent.current) {
      if (!showVideo) {
        if (btnContent.current.contains(e.target)) {
          return;
        }
      }
      if (videoContent.current.contains(e.target)) {
        return;
      }
      setShowVideo(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutside);
    return () => {
      document.removeEventListener('click', handleOutside);
    };
  }, []);

  return (
    <Layout>
      <PageHero title="ABOUT_PAGE_TITLE" subtitle="ABOUT_PAGE_SUBTITLE" />
      {showVideo && (
        <>
          <div className="video-bgr" />
          <div className="video-container">
            <div className="video-content">
              <div className="video-iframe">
                <div className="video">
                  <button title="Close (Esc)" type="button" onClick={() => setShowVideo(false)}>
                    ×
                  </button>
                  {
                    <video autoPlay controls preload="auto" ref={videoContent}>
                      <source
                        src={'https://storage.googleapis.com/website.hexometer.com/CV%202394.mp4'}
                        type="video/mp4"
                      />
                    </video>
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="light-grey text-center  pd-top-40">
        <Tabs />
      </div>
      {fields.map((item, index) => (
        <section
          key={item.alt}
          className={classNames('services', {grey: index % 2 !== 0}, {'light-grey': index % 2 === 0})}
        >
          <div className="container">
            <div
              className={classNames('row_services', {
                'flow-reverse': index % 2 !== 0,
              })}
            >
              <div className="col-12 col-lg-6 p-0">
                {item.text}
                <div ref={index === 1 ? btnContent : null}>
                  {index === 1 ? (
                    <Button aboutVideo className="trial_btn" onClick={() => setShowVideo(true)}>
                      <FontAwesomeIcon icon={faPlay} className="mr-3" />
                      Watch video
                    </Button>
                  ) : (
                    // <UserWrap
                    //   withLoading={() => (
                    //     <Button primary className="trial_btn" onClick={() => navigate('/#registration')}>
                    //       <Translate name="HOME_FREE_TRIAL_BTN" />
                    //     </Button>
                    //   )}
                    //   withoutUser={() => (
                    //     <Button primary className="trial_btn" onClick={() => navigate('/#registration')}>
                    //       <Translate name="HOME_FREE_TRIAL_BTN" />
                    //     </Button>
                    //   )}
                    //   withUser={() => (
                    //     <Button primary className="trial_btn" onClick={() => navigate('/pricing/')}>
                    //       <Translate name="HOME_FREE_TRIAL_BTN" />
                    //     </Button>
                    //   )}
                    // />
                  <></>)}
                </div>
              </div>
              <div className="col-12 col-lg-6 p-0 text-center">
                <img src={item.image} alt={item.alt} className="service-img" />
              </div>
            </div>
          </div>
        </section>
      ))}
      <section className="services grey">
        <div className="container">
          <div className="col-12">
            <Translate name="ABOUT_PARAGRAPH_FOUR" />
          </div>
          <div className="col-12 about-section-four">
            <div className="p-3">
              <img src={analytics} alt="Analytics" />
            </div>
            <div className="p-3">
              <h1 className="col-12 f_size_30 f_700 l_height50 d-flex p-0 mb_90 title" style={{textAlign: 'start'}}>
                Beyond
                <Typewriter
                  options={{
                    strings: texts,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>
              <p className="f_size_24 l_height30">
                <Translate name="HOME_SECTION_ONE_TITLE" />
              </p>
              <div className="image-div">
                <img
                  src={hexometerLogo}
                  alt="Hexometer is AI sidekick to protect and grow eCommerce business"
                  width={150}
                  height={36}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services light-grey">
        <div className="container">
          <div className="col-12">
            <Translate name="ABOUT_PARAGRAPH_FIVE" />
          </div>
          <div className="col-12 text-center p-0">
            {/* <UserWrap
              withLoading={() => (
                <Button primary className="trial_btn" onClick={() => navigate('/#registration')}>
                  <Translate name="HOME_FREE_TRIAL_BTN" />
                </Button>
              )}
              withoutUser={() => (
                <Button primary className="trial_btn" onClick={() => navigate('/#registration')}>
                  <Translate name="HOME_FREE_TRIAL_BTN" />
                </Button>
              )}
              withUser={() => (
                <Button primary className="trial_btn" onClick={() => navigate('/pricing/')}>
                  <Translate name="HOME_FREE_TRIAL_BTN" />
                </Button>
              )}
            /> */}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
